import React,{useState} from 'react';


import Grid from '@mui/material/Grid';
import './header.css'
// import LOGO from "../../../images/FMLogo.svg"
import LOGO from "../../../images/FMLogo.png"

import NavbarB from './navbarB';
function HeaderB() {
       
   const [open,setOpen]=useState(false)
   const handleClose=()=>{
       setOpen(false)
   }
        return(
            <>
            <nav className='D6_SL_Navbar_Items'>
<Grid container className='Grid_MC'>
    <Grid container className='grid_cont' sm={6}>
        <Grid container className='Grid_TC' sm={6}>

    <Grid item className='filly_icon' sm={12}>
        <img onClick={()=>window.location.href='/'} style={{height:'100px'}} src={LOGO}></img>
    </Grid>
    </Grid>
 
    </Grid>
    <Grid item style={{alignSelf:'center'}} className='grid_multi' sm={6} xs={12}>
   {/* <NavbarB /> */}
     </Grid>
 
    </Grid>                  
            </nav>
            </>
        );
    }
    
    export default HeaderB;