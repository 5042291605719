import { Container, Grid } from "@mui/material";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea, Typography } from '@mui/material';
import React, { useState ,useEffect} from "react";
import './blogsSub.css'
import './blogsMain.css'
import CircularProgress from '@mui/material/CircularProgress';
import { apilink } from "../../App";
import { Helmet } from "react-helmet";
export default function BlogsSub(props) {
  const {page,adv}=props
  const [blogData,setBlogData]=useState([])
  const [reload,setReload]=useState(0)
  const [blogData1,setBlogData1]=useState([])

  const urlParams = new URLSearchParams(window.location.search);
 
 
useEffect(() => {
if(urlParams.get('fmsID')){
  fetch(apilink+`fm-add?param=${urlParams.get('fmsID')}`).then(res=>res.json()).then(resp=>addRes(resp))
}
}, [])
 
const addRes=(res)=>{
  if(res.status){
window.location.replace(`${res.data.param_value}`)
  }
 
}

  useEffect(() => {
    async function fetchApi(){
      fetch(apilink+'fm-blog-data3',
      { method: 'GET',
      headers:{
        'Content-Type' :'application/json'  
      },})
      .then(res => res.json())
      .then(resp=>setBlogData(resp))
      .catch(err=>console.log(err))


    }
    fetchApi()
  }, [])
  useEffect(() => {
    async function fetchApi(){
      fetch(apilink+'fm-blog-data3',
      { method: 'GET',
      headers:{
        'Content-Type' :'application/json'  
      },})
      .then(res => res.json())
      .then(resp=>setBlogData1(resp))
      .catch(err=>console.log(err))


    }
    fetchApi()
  }, [reload])

  useEffect(() => {
    if(blogData&&blogData[0]){

      var fdata=blogData.filter((val)=>val.urlName.includes(page))

      if(fdata&&fdata[0]){


        const formData=new FormData()
        formData.append('views',fdata[0].views+1)
        formData.append('id',fdata[0].id)
    
    
    fetch(apilink+'fm-blog3',{
     method:'PUT',
     body:formData
      
    }).then(resp=>resp.json())  
    .then((res)=>respSave(res)) 
      }

    }
  }, [blogData])

  const respSave=(val)=>{
setReload(reload+1)
    }
  
  if(blogData&&blogData[0]&&adv&&adv[0]){
   var fdata=blogData.filter((val)=>val.urlName.includes(page))
   if(fdata&&fdata[0]){


  return (
      <div>
        <>
          {blogData.filter((val)=>val.urlName.includes(page)).sort((a,b)=>a.id<b.id?-1:1).map((val1,idx)=>{
  return(


    <Container style={{maxWidth:1440}} key={idx}>
         <Helmet>
        <meta
          name="description"
          content={val1.metaDescription}
        />
            <meta name="keywords" content={val1.metaKeywords} />
            <meta itemprop="name" content="Taxi Tour" />
            <meta
    itemprop="description"
    content={val1.metaDescription}
  />
  <meta property="og:url" content={`https://taxi-tour.com/${val1.urlName}`}/>
        <meta property="og:type" content="website" />
        <meta property="og:title" content={val1.metaTitle} />
        <meta
          property="og:description"
          content={val1.metaDescription}
        />
        </Helmet>
      <Grid container style={{paddingTop:'20vh',paddingBottom:30}}>
        <Grid container className="grid_blog" sm={8} xs={12}>
          <Grid container style={{marginBottom:40}}>
 
          <Grid className="blogSub_grid" item sm={12} xs={12}>
          <Grid item sm={12} xs={12}>
            <Typography className="heading_Sub">{val1.valueHead}</Typography>
            
            </Grid>
            <Grid container style={{paddingTop:20}} sm={12} xs={12}>
              <Grid container style={{alignItems:'center',paddingBottom:30}} sm={5} xs={12}> 
              <i class="far fa-user"></i>
            <Typography style={{paddingLeft:5}} className="subSection" >{val1.createdBy}</Typography> 
              </Grid>
              <Grid  container style={{alignItems:'center',paddingBottom:30}} sm={4} xs={6}> 
              <i class="far fa-clock"></i>
            <Typography style={{paddingLeft:5}} className="subSection" >{val1.createdOn}</Typography> 
       </Grid>
              <Grid container style={{alignItems:'center',paddingBottom:30}}  sm={3} xs={6}>
              <i class="fas fa-eye"></i>
            <Typography style={{paddingLeft:5}} className="subSection" >{val1.views+1} </Typography> 
  </Grid>
            
            </Grid>
            <Grid>
               <img src={val1.image} className="img_sub" />
            </Grid>
            {val1.value1?<Grid  item style={{alignItems:'center'}} sm={12} xs={12}>
              <Typography dangerouslySetInnerHTML={{__html:val1.value1}} className="heading_A_Sub">
</Typography>
            </Grid>:null}
            
           

          

         
          
          </Grid>
          </Grid>
        
        </Grid>

        <Grid className="small_grid" container sm={4} xs={12}>

          <Grid container >
          {adv.map((val1,idx)=>{
  return(
          <Grid item sm={12} xs={12}>
  
            <Card onClick={()=>window.open(val1.url,'_blank')}  sx={{ maxWidth: '100%' }} >
<CardActionArea>
<CardMedia
component="img"
height="250"
image={val1.imageName}
alt="green iguana"
/>

</CardActionArea>
</Card> 
            </Grid>
             )
            })} 
        <Grid container style={{justifyContent:'center',alignItems:'center'}} sm={12} xs={12}>
        <Typography className="most_popular">MOST POPULAR</Typography>
        <i class="fas fa-bell"></i>
        </Grid>
        {(blogData&&!blogData1[0]?blogData:blogData1).filter((val,idx)=>(val.active===true&&idx<6)).sort((a,b)=>a.views<b.views?1:-1).map((val1,idx)=>{
  return(
        <Grid key={idx}  onClick={()=>window.location.href=`/${val1.urlName}`} container style={{marginBottom:30,cursor:'pointer'}} className='small_gridM'  sm={12} xs={12}>
        <Grid style={{textAlign:'-webkit-center'}} item sm={5} xs={12}>
          <Card sx={{ maxWidth: 205 }} >
<CardActionArea>
<CardMedia
component="img"
height="120"
image={val1.image}
alt="green iguana"
/>

</CardActionArea>
</Card>
          </Grid>
          <Grid item style={{textAlign:'center'}} sm={7} xs={12}>
 <Grid item sm={12} xs={12}>
   <Typography className="most_popularT">
   {val1.valueHead}
   </Typography>
 </Grid>
 <Grid item sm={12} xs={12}>
   <Typography className="most_popularV">
   <b>Views:</b>{val1.views}
   </Typography>
 </Grid>
          </Grid>
        </Grid>
      )
    })} 
        </Grid>
        
        </Grid>
      </Grid>
    </Container>  )
  })} </>
    </div>)}
    else{
      window.location.href='/'
    }
}
else{
  return(
    <Grid sm={12} style={{textAlign:'center'}} xs={12} item>
        <CircularProgress style={{justifyContent:'center',height:60,width:60,marginTop:'40vh',marginBottom:'10vh'}} />
        </Grid>)
}}
