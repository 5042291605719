import { Grid ,Container} from '@mui/material'
import React from 'react'
import './D6_SL_Footer_CSS.css'
import { NavLink } from 'react-router-dom'

const D6_SL_Footer = () => {
    return (
        <div className="Dec6_Footer_Items_Block">
        <Container className="Dec6_Footer_Items">
            
            <div className="Dec6_Footer_Items_NavLink Dec6_Footer_Items_NavLink1">
                <div className="Dec6_Footer_Link" onClick={()=>window.location.href='/'} exact to="/">
                        HOME
                </div>
            </div>
            <div className="Dec6_Footer_Items_NavLink Dec6_Footer_Items_NavLink2">
                <div className="Dec6_Footer_Link" onClick={()=>window.location.href='/privacypolicy'} >
                        PRIVACY POLICY
                </div>
            </div>
            <div className="Dec6_Footer_Items_NavLink Dec6_Footer_Items_NavLink3">
                <div  className="Dec6_Footer_Link" onClick={()=>window.location.href="/contactus"}>
                    CONTACT US
                </div>
            </div>
            <div className="Dec6_Footer_Items_P_Div">  
                <p className="Dec6_Footer_Items_P">
                    &#169;2022 Taxi-Tour

 
                </p>
            </div>
        </Container>
    </div>
    )
}

export default D6_SL_Footer
