import React,{useState} from 'react';
import { Dialog, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import Grid from '@mui/material/Grid';
import './header.css'
// import LOGO from "../../../images/FMLogo.svg"
import LOGO from "../../../images/FMLogo.png"
import Qr  from "../../../images/qr-code.png"
import Navbar from './navbar';
function Header() {
       
   const [open,setOpen]=useState(false)
   const handleClose=()=>{
       setOpen(false)
   }
        return(
            <>
        {/*    <nav className="D6_SL_Navbar_Items">  */}
            <nav className='D6_SL_Navbar_Items'>
<Grid container className='Grid_MC'>
    <Grid container className='grid_cont' sm={6}>
        <Grid container className='Grid_TC' sm={6}>
    {/* <Grid item sm={1}> */}
    {/* <i class="fas fa-envelope"></i>    </Grid> */}
    <Grid item className='filly_icon' sm={12}>
        <img onClick={()=>window.location.href='/'} style={{height:'100px'}} src={LOGO}></img>
    </Grid>
    </Grid>
 
    </Grid>
    <Grid item style={{alignSelf:'center'}} className='grid_multi' sm={6} xs={12}>
   {/* <Navbar /> */}
     </Grid>
    {/* <Grid item className='grid_multi' sm={6} xs={12}>
    <i  onClick={()=>window.open('https://affiliate.fmstracking.online/','_blank')} className="fas fa-user-lock multi_icons "></i>

    <i  onClick={()=>window.open('https://www.linkedin.com/company/fillymedia.com','_blank')} className="fab fa-linkedin multi_icons "></i>
    <i  onClick={()=>window.open('https://www.facebook.com/FillyMediacom','_blank')} className="fab fa-facebook multi_icons "></i>
    </Grid> */}
    {/* <Dialog
        open={open}
        // TransitionComponent={Transition}
        onClose={handleClose}
        className='Dialog_CButton'
        disableBackdropClick
        PaperProps={{ elevation: 0 }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className='icon-close-btn1'>
            <CloseIcon />
          </IconButton>
        
<div>
<img style={{height:'200px',width:'200px'}} src={Qr}></img>

    </div>        
        </DialogContent>
      </Dialog> */}
    </Grid>                  
            </nav>
            </>
        );
    }
    
    export default Header;